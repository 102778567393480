function get_timer2() {
    $('.timer').each(function () {
        timetext = $(this).data('timetext');
        timeend = $(this).data('timeend');
        var date_t = new Date(timeend);
        var date = new Date();
        var timer = date_t - date;
        if (date_t > date) {
            var day = parseInt(timer / (60 * 60 * 1000 * 24));
            if (day < 10) {
                day = '0' + day;
            }
            day = day.toString();
            var hour = parseInt(timer / (60 * 60 * 1000)) % 24;
            if (hour < 10) {
                hour = '0' + hour;
            }
            hour = hour.toString();
            var min = parseInt(timer / (1000 * 60)) % 60;
            if (min < 10) {
                min = '0' + min;
            }
            min = min.toString();
            var sec = parseInt(timer / 1000) % 60;
            if (sec < 10) {
                sec = '0' + sec;
            }
            sec = sec.toString();
            if (day == 0) {
                var timestr = '<span class="t-day" >' + day + '</span><span class="t-hour">' + hour + '</span><span class="t-min">' + min + '</span><span class="t-sec">' + sec + '</span>';
            } else {
                var timestr = '<span class="t-day" >' + day + '</span><span class="t-hour">' + hour + '</span><span class="t-min">' + min + '</span><span class="t-sec">' + sec + '</span>';
            }
            $(this).html('<span class="banner_timer"> ' + timestr + '</span>');
        } else {
            $(this).html("");
        }
    });
    setTimeout(get_timer2, 1000);
}

function getURLVar(key) {
    var value = [];
    var query = String(document.location).split('?');
    if (query[1]) {
        var part = query[1].split('&');
        for (i = 0; i < part.length; i++) {
            var data = part[i].split('=');
            if (data[0] && data[1]) {
                value[data[0]] = data[1];
            }
        }
        if (value[key]) {
            return value[key];
        } else {
            return '';
        }
    }
}

function subscribe() {
    var emailpattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var email = $('#txtemail').val();
    if (email != "") {
        $.ajax({
            url: 'index.php?route=module/newsletters/news',
            type: 'post',
            data: 'email=' + $('#txtemail').val(),
            dataType: 'json',
            success: function (json) {
                alert(json.message);
            }
        });
        return false;
    } else {
        $(email).focus();
        return false;
    }
}

function homeSlider() {
    if ($('*').is('.home-slider')) {
        /*if ($(window).width() > 1200) {
            $('.home-slider .item, .home-slider .item .slider-container').css('height', $(window).height() - 147);
        }*/

        var $status = $('.custom_paging');
        var $slickElement = $('.home-slider');

        $slickElement.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
            //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
            if(!slick.$dots){
                return;
            }

            var i = (currentSlide ? currentSlide : 0) + 1;
            $status.html('<span>0' + i + '</span>' + '&nbsp;/&nbsp;' + '0' + (slick.$dots[0].children.length));
        });


        $('.home-slider').slick({
            dots: true,
            autoplay: true,
            infinite: true,
            arrows: true,
            prevArrow: '<span class="icon_new icon_new-arrow_left"></span>',
            nextArrow: '<span class="icon_new icon_new-arrow_right"></span>',
            speed: 300,
            slidesToShow: 1,
            //adaptiveHeight: true
            dotsClass: 'custom_paging',
            customPaging: function (slider, i) {
                //FYI just have a look at the object to find aviable information
                //press f12 to access the console
                //you could also debug or look in the source
                //console.log(slider);
                //return '<span>0' + (i + 1) + '</span>' + '&nbsp;/&nbsp;' + '0' + slider.slideCount;
                return '<span>0' + (i + 1) + '</span>';
            },
            appendArrows: $('.home-slider-container').find('.custom_pag_container .arrows'),
            appendDots: $('.home-slider-container').find('.custom_pag_container .dots'),
            responsive: [{
                breakpoint: 768, settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    appendArrows: $('.home-slider-container').find('.custom_pag_container .dots'),
                }}
            ]
        });
    }
}



function productsSlider() {
    if ($('.products-slider').length) {

        $('.products-slider').each( function() {
            var $slickElement = $(this);

            $slickElement.on('init reInit', function (event, slick) {
                var count_slides = $(this).find('.slick-track .slick-slide').length;
                if(count_slides <=3 ){
                    $(this).parents('.product-box-slider').addClass('less_products');
                }

            });
        })

        $('.products-slider').each( function() {
            $( this ).slick( {
                dots: true,
                autoplay: false,
                infinite: true,
                arrows: true,
                //centerMode: true,
                //centerPadding: 0,
                prevArrow: '<span class="icon_new icon_new-arrow_left"></span>',
                nextArrow: '<span class="icon_new icon_new-arrow_right"></span>',
                speed: 300,
                slidesToShow: 3,
                slidesToScroll:3,
                //adaptiveHeight: true
                dotsClass: 'custom_paging',
                customPaging: function (slider, i) {
                    return '<span>0' + (i + 1) + '</span>';
                },
                appendArrows: $(this).parents('.product-box-slider').find('.custom_pag_container .arrows'),
                appendDots: $(this).parents('.product-box-slider').find('.custom_pag_container .dots'),
                responsive: [{
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        infinite: true,}
                }, {
                    breakpoint: 768, settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        appendArrows: $(this).parents('.product-box-slider').find('.custom_pag_container .dots'),
                    }}
                ]
            } );
        } );
    }
}

function homeCategoriesSlider() {
    if ($('.home-categories-slider').length) {
        $('.home-categories-slider').slick( {
            dots: true,
            autoplay: false,
            infinite: true,
            arrows: true,
            //centerMode: true,
            //centerPadding: 0,
            prevArrow: '<span class="icon_new icon_new-arrow_left"></span>',
            nextArrow: '<span class="icon_new icon_new-arrow_right"></span>',
            speed: 300,
            slidesToShow: 4,
            slidesToScroll:4,
            dotsClass: 'custom_paging',
            customPaging: function (slider, i) {
                return '<span>0' + (i + 1) + '</span>';
            },
            appendArrows: $('.home-categories').find('.custom_pag_container .arrows'),
            appendDots: $('.home-categories').find('.custom_pag_container .dots'),
            responsive: [{
                breakpoint: 991,
                settings: {slidesToShow: 4, slidesToScroll: 4, infinite: true}},{
                breakpoint: 768, settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    appendArrows: $('.home-categories').find('.custom_pag_container .dots'),
                }}
            ],
        });
    };
}

function latestSlider() {
    if ($('*').is('.js-latest-slider')) {
        $('.js-latest-slider').slick({
            dots: true,
            infinite: true,
            autoplay: false,
            arrows: true,
            nextArrow: '<i class="icon-arrow-right arrow-next"></i>',
            prevArrow: '<i class="icon-arrow-left arrow-prev"></i>',
            speed: 300,
            slidesToShow: 4,
            adaptiveHeight: true,
            slidesToScroll: 4,
            responsive: [{
                breakpoint: 1200,
                settings: {slidesToShow: 3, slidesToScroll: 3, infinite: true,}
            }, {breakpoint: 767, settings: {slidesToShow: 2, slidesToScroll: 2}}, {
                breakpoint: 480,
                settings: {slidesToShow: 1, slidesToScroll: 1}
            }]
        });
    }
}

function BestSellerSlider() {
    if ($('*').is('.js-bestseller-slider')) {
        $('.js-bestseller-slider').slick({
            dots: true,
            infinite: true,
            autoplay: false,
            arrows: true,
            nextArrow: '<i class="icon-arrow-right arrow-next"></i>',
            prevArrow: '<i class="icon-arrow-left arrow-prev"></i>',
            speed: 300,
            slidesToShow: 4,
            adaptiveHeight: true,
            slidesToScroll: 4,
            responsive: [{
                breakpoint: 1200,
                settings: {slidesToShow: 3, slidesToScroll: 3, infinite: true,}
            }, {breakpoint: 767, settings: {slidesToShow: 2, slidesToScroll: 2}}, {
                breakpoint: 480,
                settings: {unslick: true}
            }]
        });
    }
}
function BestSellerBanners() {
    if ($('*').is('.js-bestseller-banners')) {
        $('.js-bestseller-banners').slick({
            dots: true,
            infinite: true,
            autoplay: false,
            arrows: true,
            nextArrow: '<i class="icon-arrow-right arrow-next"></i>',
            prevArrow: '<i class="icon-arrow-left arrow-prev"></i>',
            speed: 300,
            slidesToShow: 1,
            adaptiveHeight: true,
            slidesToScroll: 1,
            unslick: true,
            responsive: [{
                breakpoint: 480,
                settings: {
                    unslick: true
                }
            }]
        });
    }
}

function RelatedSlider() {
    if ($('*').is('.js-relatet-product')) {
        $('.js-relatet-product').slick({
            dots: true,
            infinite: true,
            autoplay: false,
            arrows: true,
            nextArrow: '<i class="icon-arrow-right arrow-next"></i>',
            prevArrow: '<i class="icon-arrow-left arrow-prev"></i>',
            speed: 300,
            slidesToShow: 4,
            adaptiveHeight: true,
            slidesToScroll: 4,
            responsive: [{
                breakpoint: 1200,
                settings: {slidesToShow: 3, slidesToScroll: 3, infinite: true,}
            }, {breakpoint: 767, settings: {slidesToShow: 2, slidesToScroll: 2}}, {
                breakpoint: 480,
                settings: {slidesToShow: 1, slidesToScroll: 1}
            }]
        });
    }
}

function specialSlider() {
    if ($('*').is('.js-special-slider')) {
        $('.js-special-slider').slick({
            dots: false,
            infinite: true,
            autoplay: false,
            arrows: true,
            nextArrow: '<i class="icon-arrow-right arrow-next"></i>',
            prevArrow: '<i class="icon-arrow-left arrow-prev"></i>',
            speed: 300,
            slidesToShow: 4,
            adaptiveHeight: true,
            slidesToScroll: 4,
            responsive: [{
                breakpoint: 1200,
                settings: {slidesToShow: 3, slidesToScroll: 3, infinite: true,}
            }, {breakpoint: 767, settings: {slidesToShow: 2, slidesToScroll: 2}}, {
                breakpoint: 480,
                settings: {slidesToShow: 1, slidesToScroll: 1}
            }]
        });
    }
}

function CategoryHomeSlider() {
    if ($('*').is('.col-home-category-slider')) {
        $('.col-home-category-slider').slick({
            dots: true,
            infinite: true,
            autoplay: false,
            arrows: true,
            nextArrow: '<i class="icon-arrow-right arrow-next"></i>',
            prevArrow: '<i class="icon-arrow-left arrow-prev"></i>',
            speed: 300,
            slidesToShow: 4,
            adaptiveHeight: true,
            slidesToScroll: 4,
            responsive: [{
                breakpoint: 1200,
                settings: {slidesToShow: 3, slidesToScroll: 3, infinite: true,}
            }, {breakpoint: 767, settings: {slidesToShow: 2, slidesToScroll: 2}}, {
                breakpoint: 480,
                settings: {slidesToShow: 1, slidesToScroll: 1}
            }]
        });
    }
}

function gallerySlider() {
    if ($('*').is('.gallery-slider')) {
        setTimeout(function () {
            var offset = $('.gallery-slider .slick-dots').offset();
            var qdots = $('.gallery-slider .slick-dots > li').length
            if ((window.innerWidth > 1199)) {
                $('.gallery-slider .arrow-prev').css('left', offset.left + 30);
                $('.gallery-slider .arrow-next').css('left', (offset.left + 35 * qdots + 55));
            }
        }, 300)
        $('.gallery-slider').slick({
            dots: true,
            infinite: true,
            autoplay: false,
            arrows: true,
            nextArrow: '<i class="icon-arrow-right arrow-next"></i>',
            prevArrow: '<i class="icon-arrow-left arrow-prev"></i>',
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 3,
            responsive: [{
                breakpoint: 1200,
                settings: {slidesToShow: 2, slidesToScroll: 3, dots: true}
            }, {breakpoint: 767, settings: {slidesToShow: 2, slidesToScroll: 1, dots: false}}, {
                breakpoint: 481,
                settings: {slidesToShow: 1, slidesToScroll: 1, centerMode: false, dots: false}
            }]
        });
    }
}

function newcollectionSlider() {
    if ($('*').is('.js-newcollection-slider')) {
        $('.js-newcollection-slider').on('init', function (event, slick) {
            $(".js-newcollection-slider .slick-active").each(function (index) {
                if (index == 0) {
                    $(this).addClass('big-slide');
                    $(this).prev().addClass('hidden-slide');
                }
                if (index == 2) {
                    $(this).next().addClass('blure-slide');
                }
            });
        });
        $('.js-newcollection-slider').slick({
            dots: false,
            infinite: true,
            autoplay: true,
            arrows: true,
            nextArrow: '<i class="icon-right-arrow-1 arrow-next"></i>',
            prevArrow: '<i class="icon-right-arrow-2 arrow-prev"></i>',
            speed: 300,
            slidesToShow: 4,
            adaptiveHeight: false,
            slidesToScroll: 1,
            centerMode: false,
            variableWidth: false,
            centerPadding: '85px',
            initialSlide: 0,
            responsive: [{
                breakpoint: 1200,
                settings: {slidesToShow: 2, slidesToScroll: 2, infinite: true,}
            }, {breakpoint: 749, settings: {slidesToShow: 1, slidesToScroll: 1}}]
        });
        $('.js-newcollection-slider').on('swipe', function (event, slick, direction) {
        });
        $('.js-newcollection-slider').on('edge', function (event, slick, direction) {
        });
        $('.js-newcollection-slider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
            $('.js-newcollection-slider .slick-slide').removeClass('hidden-slide');
        });
        $('.js-newcollection-slider').on('afterChange', function (event, slick, currentSlide) {
            $(".js-newcollection-slider .slick-active").each(function (index) {
                if (index == 0) {
                    $(this).prev().addClass('hidden-slide');
                }
            });
        });
    }
}

function reviewSlider() {
    if ($('*').is('.js-review-slider')) {
        $('.js-review-slider').slick({
            dots: true,
            infinite: true,
            arrows: true,
            nextArrow: '<i class="icon-right-arrow-1 arrow-next"></i>',
            prevArrow: '<i class="icon-right-arrow-2 arrow-prev"></i>',
            speed: 300,
            slidesToShow: 1,
            adaptiveHeight: true,
            responsive: [{breakpoint: 1200, settings: {slidesToShow: 1, slidesToScroll: 1, infinite: true,}}]
        });
    }
}

function BlogGallery() {
    if ($('*').is('.js-blog-gallery')) {
        $('.js-blog-gallery ').lightGallery({
            loop: true,
            fourceAutoply: true,
            thumbnail: true,
            hash: false,
            speed: 400,
            scale: 1,
            keypress: true,
            counter: false,
            download: false,
        });
        var wall = new Freewall(".js-blog-gallery");
        wall.reset({
            selector: '.brik',
            gutterX: 30,
            gutterY: 30,
            animate: true,
            cellW: 300,
            cellH: 'auto',
            onResize: function () {
                wall.fitWidth();
            }
        });
        wall.fitWidth();
    }
}


function EditMinQuant(product_id) {
    var qty = $('.item-' + product_id).val();
    if ((parseFloat(qty) != parseInt(qty)) || isNaN(qty)) {
        qty = 1;
    } else {
        qty = Number(qty) - 1;
        if (qty < 1) qty = '1';
    }
    $('.item-' + product_id).val(qty);
}

function EditMaxQuant(product_id) {
    var qty = $('.item-' + product_id).val();
    if ((parseFloat(qty) != parseInt(qty)) || isNaN(qty)) {
        qty = 1;
    } else {
        qty = Number(qty) + 1;
    }
    $('.item-' + product_id).val(qty);
}

function get_popup_purchase(product_id) {
    var url = 'index.php?route=module/popup_purchase&product_id=' + product_id;
    if (url.indexOf('#') == 0) {
        $(url).modal('open');
    } else {
        $.get(url, function (data) {
            $('<div class="modal fade modal-buy-one-click" id="buy-one-click" tabindex="-1" role="dialog">' + data + '</div>').modal();



        }).success(function () {
            $('input:text:visible:first').focus();
        });

        $('#buy-one-click').modal('show');
    }
}

function collapseAboutCompany(){
    if ($('.box-about-company').length){
        $('.box-about-company .readmore a').click(function(){
            $(this).toggleClass('full');
            $(this).find('span').toggleClass('hidden');
            $('.box-about-company .html_content').toggleClass('full');
        })
    }
}


function mobileMenu(){
    $('button.hamburger').click(function(){
        $(this).toggleClass('active');
        $('body').toggleClass('overflow_hidden');
        $('#mob_menu_container').toggleClass('active');
    })

}


function productImageSlider() {
    if ($('*').is('.slider-image-big')) {
        $('.slider-image-big').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            nextArrow: '<i class="icon_new icon_new-arrow_right arrow-next"></i>',
            prevArrow: '<i class="icon_new icon_new-arrow_left arrow-prev"></i>',
            fade: true,
            asNavFor: '.slider-image-additional'
        });
        $('.slider-image-additional').slick({
            slidesToShow: 5,
            slidesToScroll: 1,
            asNavFor: '.slider-image-big',
            dots: false,
            arrows: false,
            centerMode: true,
            focusOnSelect: true,
            centerPadding: '0px',
        });
        if ($(window).width() > 767) {
            $('.slider-image-big').on('afterChange', function (event, slick, currentSlide) {
                $(".slider-image-big .slick-current img").elevateZoom({
                    zoomType: "lens",
                    lensShape: "square",
                    lensSize: 160,
                    borderSize: 0
                });
            });
            $(".slider-image-big .slick-current img").elevateZoom({
                zoomType: "lens",
                lensShape: "square",
                lensSize: 160,
                borderSize: 0
            });
        }
        $('#lightgallery').lightGallery({
            loop: true,
            fourceAutoply: true,
            thumbnail: true,
            hash: false,
            speed: 400,
            scale: 1,
            keypress: true,
            counter: false,
            download: false,
        });
        $('.click-triger-zoom').on('click', function (e) {
            $('.slider-image-big .slick-current img').data("link");
            $('#' + $('.slider-image-big .slick-current img').data("link")).trigger("click");
            // console.log($('.slider-image-big .slick-current img').data("link"));
        })
    }
}


function equalheight(container) {
    var currentTallest = 0, currentRowStart = 0, rowDivs = new Array(), $el, topPosition = 0;
    $(container).each(function () {
        $el = $(this);
        $($el).height('auto')
        topPostion = $el.position().top;
        if (currentRowStart != topPostion) {
            for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                rowDivs[currentDiv].height(currentTallest);
            }
            rowDivs.length = 0;
            currentRowStart = topPostion;
            currentTallest = $el.height();
            rowDivs.push($el);
        } else {
            rowDivs.push($el);
            currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
        }
        for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
            rowDivs[currentDiv].height(currentTallest);
        }
    });
}

function masked(element, status) {
    if (status == true) {
        $(element).prepend('<div class="masked"></div><div class="masked_loading" ></div>');
    } else {
        $('.masked').remove();
        $('.masked_loading').remove();
    }
}

function CheckouteResize() {
    var ccr = $('.col-customer-radio');
    var sp = $('#simplecheckout_payment');
    sp.before(ccr);
}

function headerMenuCatalog(){
    $('.btn-more_cat').click(function() {
        $(this).toggleClass('open');
        $(this).closest('.childe-menu-inner').find('ul').toggleClass('open');
    });
}
function homeSliderYoutube(){
    $('.slider-youtube-js').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: true,
        nextArrow: '<span class="slider-arrow arrow-next"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path d="M3 13L17.586 13L12.293 18.293L13.707 19.707L21.414 12L13.707 4.29303L12.293 5.70703L17.586 11L3 11L3 13Z" fill="#595959"/>\n' +
            '</svg></span>',
        prevArrow: '<span class="slider-arrow arrow-prev"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path d="M21 11L6.414 11L11.707 5.70697L10.293 4.29297L2.586 12L10.293 19.707L11.707 18.293L6.414 13L21 13L21 11Z" fill="#595959"/>\n' +
            '</svg></span>',
        centerMode: false,
        autoplay: false,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    arrows: false,
                }
            }
        ]
    }).on('beforeChange', function (event, slick, currentSlide) {
        var frame = $('.slider-youtube_slide[data-slick-index="'+currentSlide+'"]').find('iframe');
        var property = frame.data('video');
        $(frame).removeProp('src');
        $(frame).prop('src',property);
        $(frame).closest('.video').find('.video-placeholder-js')[0].style.display = "block";
    });

    $('.video-placeholder-js').on('click', function (){
        $(this)[0].style.display = "none";
        var frame = $(this).closest('.video').find('iframe');
        var property = frame.prop('src');
        var autoplay = "?autoplay=1";
        if(property.indexOf( autoplay ) != -1){
            $(frame).prop('src',property);
        } else {
            property += autoplay;
            $(frame).prop('src',property);
        }
    });
}

$(document).ready(function () {
    mobileMenu();
    homeSlider();
    productsSlider();
    homeCategoriesSlider();
    collapseAboutCompany();
    productImageSlider();
    headerMenuCatalog();

    homeSliderYoutube();

    get_timer2();
    gallerySlider();
    latestSlider();
    BestSellerSlider();
    BestSellerBanners();
    specialSlider();
    newcollectionSlider();
    //instagramSlider();
    reviewSlider();
    CategoryHomeSlider();
    RelatedSlider();
    BlogGallery();
    CheckouteResize();


    if (!("ontouchstart" in document.documentElement)) {
        document.documentElement.className += " no-touch";
    }else{
        document.documentElement.className += " is-touch";
    }



    $('#cart').click(function(){
        location = 'index.php?route=checkout/simplecheckout';
    });



    if ($(window).width() > 768) {
        $('#new_header').affix({offset: {top: 1}});
    } else {
        $('#new_header').affix({offset: {top: 180}});
    }



    $('.input-qty').on('keyup', function () {
        $(this).val($(this).val().replace(/\D/, ''));
    });



    $('.ulogin-button-facebook').on('click', function (e) {
        setTimeout(function () {
            $('#modal-quick-login-register').modal('hide')
        }, 800)
    });

    $('.header_menu > ul > li.dropdown').click(function(){
        $('body').toggleClass('overflow_hidden');
    })

    $('a.dropdown-toggle').on('click', function (e) {
        e.preventDefault();
        href = $(this).attr('href');
        if (href) {
            if ($(window).width() > 1200) {
                location = $(this).attr('href');
            } else if ($(this).parent('li').hasClass('open')) {
                console.log('qqq');
                location = $(this).attr('href');
            }
        }
    });
    // Prevents menu from closing when clicked inside
    $('.dropdown-menu').click(function (event) {
        //alert("click outside");
        event.stopPropagation();
    });



    /*menu 3 level*/
    /*$('.js-open-childe').on('click', function (e) {
        e.preventDefault();
        $(this).siblings("ul").toggle();
        $(this).toggleClass('open');
        if ($(window).width() < 768) {
            $('.catalog-link .dropdown-menu').css('display', 'block');
        }
    })*/

    /*$('.js-open-childe').on('click', function (e) {
        e.preventDefault();
    });

    $('.js-open-childe > span').on('click', function (e) {
        var url = $(this).parents('.js-open-childe').attr('href');
        console.log(url);
    })*/

    $('.childe-menu li.has_children .icon_new-arrow_down').on('click', function (e) {
        $(this).parents('.has_children').toggleClass('open');
        $(this).siblings("ul").toggle();
    });



    $('.mob_menu_container>ul>li ul li > i').click(function(){
        $(this).toggleClass('open');
        $(this).siblings(".childe-menu").toggle();
    });





    $('.js-is-chailde').on('click', function (e) {
        e.preventDefault();
        $(this).next("div").toggle();
        $(this).toggleClass('open');
      })


    $('.mfilter-box h3').on('click', function (e) {
        e.preventDefault();
        if ($(window).width() < 768) {
            $('.mfilter-content').toggle();
            $(this).toggleClass('open');
        }
    });

    $(document).delegate('.js-popup-link', 'click', function (e) {
        $('#' + $(this).data('link')).modal('show');
        console.log($(this).data('link'));
    });
    $('#customer_fax-1').click(function (e) {
        e.stopPropagation();
    });
    $('.item-video').click(function (e) {
        var action = 'playVideo';
        if ($(this).is('.open')) {
            action = 'stopVideo';
        }
        $(this).toggleClass('open');
        var videoid = $(this).data('videoid');
        setTimeout(function () {
            callPlayer(videoid, action)
        }, 100);
        console.log(action)
    });
    $('.js-phone-mask').mask('+38 (000) 000-00-00', {placeholder: "+38 (0_ _) _ _ _ - _ _ - _ _"});


    if($('select').length) {
        setTimeout(function () {
            $('select').styler();
            if ((window.innerWidth <= 650)) {
                $('#grid-view').trigger('click');
            }
        }, 100);
    }



    $('.text-danger').each(function () {
        var element = $(this).parent().parent();
        if (element.hasClass('form-group')) {
            element.addClass('has-error');
        }
    });
    $('.product-button-report').on('click', function (e) {
        e.preventDefault();
        console.log($(this).data('product_id'));
        $('#product-id').val($(this).data('product_id'));
        $('#popup-product-report').modal('show');
    });





    $('.currency .currency-select').on('click', function (e) {
        e.preventDefault();
        $('.currency input[name=\'code\']').attr('value', $(this).attr('name'));
        $('.currency').submit();
    });




    $('.language a').on('click', function (e) {
        e.preventDefault();
        $('.language input[name=\'code\']').attr('value', $(this).attr('href'));
        $('.language').submit();
    });






    $('.search input[name=\'search\']').parent().find('button').on('click', function () {
        url = $('base').attr('href') + 'index.php?route=product/search';
        //var value = $('.search input[name=\'search\']').val();
        var value = $(this).prev().val();
        if (value) {
            url += '&search=' + encodeURIComponent(value);
        }
        location = url;
    });
    $('.search input[name=\'search\']').on('keydown', function (e) {
        if (e.keyCode == 13) {
            $('.search input[name=\'search\']').parent().find('button').trigger('click');
        }
    });


    $('[data-toggle=\'tooltip\']').tooltip({container: 'body', trigger: 'hover'});
    $(document).ajaxStop(function () {
        $('[data-toggle=\'tooltip\']').tooltip({container: 'body'});
    });


    setTimeout(function () {
        equalheight($('.col-news-layout .artblock'));
    }, 300)

});


$(document).on('submit', ".oc-custom-forms", function (e) {
    e.preventDefault();
    var form = this;
    var fd = new FormData(form);
    $.ajax({
        type: 'POST',
        url: form.action,
        // data: $(form).serialize(),
        data: fd,
        dataType: 'json',
        beforeSend: function (xhr) {
        },
        success: function (data) {
            $(form).find('div.text-danger').remove();
            $(form).find('div.has-error').removeClass('error_style');
            var id;
            var label;
            var clone;
            if (data.status === 'error') {
                for (var key in data.errors) {
                    id = $(form).find('[name='+key+']').attr('id');
                    $('#'+ id).after('<div class="text-danger">' + data.errors[key] + '</div>');
                    $('#'+ id).addClass('error_style');
                }
                // $(form).find('div.form-group').not('has-error').addClass('has-success');
                // $(form).find('div.form-group.has-error').removeClass('has-success');
            } else if (data.status === 'success') {
                // $(form).find('div.form-group').not('has-error').addClass('has-success');
                $('.modal').modal('hide');
                $('.oc-custom-forms').find("input").removeClass('error_style');
                $('.oc-custom-forms').find("input[type=text], input[type=tel], input[type=email], textarea").val("");
                $('#modal-success .modal-title').html(data.title);
                $('#modal-success .modal-body-up-text').html(data.message);
                $('#modal-success').modal('show');
                /*setTimeout(function () {
                    $('#modal-success').modal('hide');
                }, 4000);*/
            }
        },
        cache: false,
        contentType: false,
        processData: false
    });
});

var reviewstar = {
    'addstar': function (product_id, rating) {
        $.ajax({
            url: 'index.php?route=product/product/writeStar',
            type: 'post',
            data: 'product_id=' + product_id + '&rating=' + rating,
            dataType: 'json',
            beforeSend: function () {
                $('#report-form .button-report-wrapper').css('opacity', '.5');
            },
            complete: function () {
            },
            success: function (json) {
                console.log(json);
                html = '<div id="modal-review" class="modal">';
                html += '  <div class="modal-dialog">';
                html += '    <div class="modal-content">';
                html += '      <div class="modal-header">';
                html += '       <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" class="icon-close"> </span></button>';
                html += '        <h3 class="modal-title">' + json["title"] + '</h3>';
                html += '      </div>';
                html += '      <div class="modal-body">' + json["success"] + '</div>';
                html += '    </div';
                html += '  </div>';
                html += '</div>';
                $('body').append(html);
                $('#modal-review').modal('show');
            },
            error: function (xhr, ajaxOptions, thrownError) {
                alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
            }
        });
    }, 'remove': function () {
    }
}
var cart = {
    'add': function (product_id, quantity) {
        console.log('add');
        $.ajax({
            url: 'index.php?route=checkout/cart/add',
            type: 'post',
            data: 'product_id=' + product_id + '&quantity=' + (typeof(quantity) != 'undefined' ? quantity : 1),
            dataType: 'json',
            beforeSend: function () {
                $('#cart > button').addClass('loading');
            },
            complete: function () {
            },
            success: function (json) {
                $('#cart > button').removeClass('loading');
                $('.alert, .text-danger').remove();
                if (json['redirect']) {
                    location = json['redirect'];
                }
                if (json['success']) {

                    if (json['fb_event']) {
                        $('body').append('<script>'+  json['fb_event'] +'</script>')

                    }


                    $('#cart #cart-total').html(json['total']);
                    $('#cart').addClass('cart-full');
                    $('#cart > ul').load('index.php?route=common/cart/info ul li');
                    /*$('#cart > button').trigger('click');
                    setTimeout(function () {
                        $('body').trigger('click');
                    }, 3000)*/

                    setTimeout(function () {
                        $( "#modal_container_addcart #cart_popup_product_list > ul" ).load( "index.php?route=common/cart/info ul li", function() {
                            $('#modal_container_addcart').modal();
                        });
                    }, 1500)
                }
            },
            error: function (xhr, ajaxOptions, thrownError) {
                alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
            }
        });
    }, 'update': function (key, quantity) {
        $.ajax({
            url: 'index.php?route=checkout/cart/edit',
            type: 'post',
            data: 'key=' + key + '&quantity=' + (typeof(quantity) != 'undefined' ? quantity : 1),
            dataType: 'json',
            beforeSend: function () {
                $('#cart > button').button('loading');
            },
            complete: function () {
                $('#cart > button').button('reset');
            },
            success: function (json) {
                setTimeout(function () {
                    $('#cart > button').html('<span id="cart-total"><i class="fa fa-shopping-cart"></i> ' + json['total'] + '</span>');
                }, 100);
                if (getURLVar('route') == 'checkout/cart' || getURLVar('route') == 'checkout/checkout') {
                    location = 'index.php?route=checkout/cart';
                } else {
                    $('#cart > ul').load('index.php?route=common/cart/info ul li');
                }
            },
            error: function (xhr, ajaxOptions, thrownError) {
                alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
            }
        });
    }, 'remove': function (key) {
        $.ajax({
            url: 'index.php?route=checkout/cart/remove',
            type: 'post',
            data: 'key=' + key,
            dataType: 'json',
            beforeSend: function () {
                $('#cart > button').button('loading');
            },
            complete: function () {
                $('#cart > button').button('reset');
            },
            success: function (json) {
                setTimeout(function () {
                    $('#cart > button').html('<span id="cart-total"><i class="fa fa-shopping-cart"></i> ' + json['total'] + '</span>');
                }, 100);
                if (getURLVar('route') == 'checkout/cart' || getURLVar('route') == 'checkout/checkout') {
                    location = 'index.php?route=checkout/cart';
                } else {
                    $('#cart > ul').load('index.php?route=common/cart/info ul li');
                }
            },
            error: function (xhr, ajaxOptions, thrownError) {
                alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
            }
        });
    }
}
var voucher = {
    'add': function () {
    }, 'remove': function (key) {
        $.ajax({
            url: 'index.php?route=checkout/cart/remove',
            type: 'post',
            data: 'key=' + key,
            dataType: 'json',
            beforeSend: function () {
                $('#cart > button').button('loading');
            },
            complete: function () {
                $('#cart > button').button('reset');
            },
            success: function (json) {
                setTimeout(function () {
                    $('#cart > button').html('<span id="cart-total"><i class="fa fa-shopping-cart"></i> ' + json['total'] + '</span>');
                }, 100);
                if (getURLVar('route') == 'checkout/cart' || getURLVar('route') == 'checkout/checkout') {
                    location = 'index.php?route=checkout/cart';
                } else {
                    $('#cart > ul').load('index.php?route=common/cart/info ul li');
                }
            },
            error: function (xhr, ajaxOptions, thrownError) {
                alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
            }
        });
    }
}
var wishlist = {
    'add': function (product_id) {
        $.ajax({
            url: 'index.php?route=account/wishlist/add',
            type: 'post',
            data: 'product_id=' + product_id,
            dataType: 'json',
            success: function (json) {
                $('.alert').remove();
                if (json['redirect']) {
                    location = json['redirect'];
                }
                if (json['success']) {
                }
                $('#wishlist-total').html('<i class="icon_new-forma-1-copy-5"></i><span > ' + json['total'] + '</span>');
                $('#wishlist-total').addClass('wishlist-full');
                if ($('*').is('#product')) {
                   $('.js-wishlist-' + product_id).addClass('red').removeAttr('title');
                }else {
                   //$('.js-wishlist-' + product_id).addClass('in_wishlist').removeAttr('title').html('<i class="icon-forma-1-copy-5"></i>');
                    $('.js-wishlist-' + product_id).addClass('red').removeAttr('title');

                }
            },
            error: function (xhr, ajaxOptions, thrownError) {
                alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
            }
        });
    }, 'remove': function () {
    }
}
var compare = {
    'add': function (product_id) {
        $.ajax({
            url: 'index.php?route=product/compare/add',
            type: 'post',
            data: 'product_id=' + product_id,
            dataType: 'json',
            success: function (json) {
                $('.alert').remove();
                if (json['success']) {
                    $('#content').parent().before('<div class="alert alert-success"><i class="fa fa-check-circle"></i> ' + json['success'] + ' <button type="button" class="close" data-dismiss="alert">&times;</button></div>');
                    $('#compare-total').html(json['total']);
                    $('html, body').animate({scrollTop: 0}, 'slow');
                }
            },
            error: function (xhr, ajaxOptions, thrownError) {
                alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
            }
        });
    }, 'remove': function () {
    }
}
$(document).delegate('.agree', 'click', function (e) {
    e.preventDefault();
    $('#modal-agree').remove();
    var element = this;
    $.ajax({
        url: $(element).attr('href'), type: 'get', dataType: 'html', success: function (data) {
            html = '<div id="modal-agree" class="modal">';
            html += '  <div class="modal-dialog">';
            html += '    <div class="modal-content">';
            html += '      <div class="modal-header">';
            html += '        <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><span aria-hidden="true" class="icon_new-close"> </span></button>';
            html += '        <h3 class="modal-title">' + $(element).data('title') + '</h3>';
            html += '      </div>';
            html += '      <div class="modal-body">' + data + '</div>';
            html += '    </div';
            html += '  </div>';
            html += '</div>';
            $('body').append(html);
            $('#modal-agree').modal('show');
        }
    });
});
(function ($) {
    $.fn.autocomplete = function (option) {
        return this.each(function () {
            this.timer = null;
            this.items = new Array();
            $.extend(this, option);
            $(this).attr('autocomplete', 'off');
            $(this).on('focus', function () {
                this.request();
            });
            $(this).on('blur', function () {
                setTimeout(function (object) {
                    object.hide();
                }, 200, this);
            });
            $(this).on('keydown', function (event) {
                switch (event.keyCode) {
                    case 27:
                        this.hide();
                        break;
                    default:
                        this.request();
                        break;
                }
            });
            this.click = function (event) {
                event.preventDefault();
                value = $(event.target).parent().attr('data-value');
                if (value && this.items[value]) {
                    this.select(this.items[value]);
                }
            }
            this.show = function () {
                var pos = $(this).position();
                $(this).siblings('ul.dropdown-menu').css({top: pos.top + $(this).outerHeight(), left: pos.left});
                $(this).siblings('ul.dropdown-menu').show();
            }
            this.hide = function () {
                $(this).siblings('ul.dropdown-menu').hide();
            }
            this.request = function () {
                clearTimeout(this.timer);
                this.timer = setTimeout(function (object) {
                    object.source($(object).val(), $.proxy(object.response, object));
                }, 200, this);
            }
            this.response = function (json) {
                html = '';
                if (json.length) {
                    for (i = 0; i < json.length; i++) {
                        this.items[json[i]['value']] = json[i];
                    }
                    for (i = 0; i < json.length; i++) {
                        if (!json[i]['category']) {
                            html += '<li data-value="' + json[i]['value'] + '"><a href="#">' + json[i]['label'] + '</a></li>';
                        }
                    }
                    var category = new Array();
                    for (i = 0; i < json.length; i++) {
                        if (json[i]['category']) {
                            if (!category[json[i]['category']]) {
                                category[json[i]['category']] = new Array();
                                category[json[i]['category']]['name'] = json[i]['category'];
                                category[json[i]['category']]['item'] = new Array();
                            }
                            category[json[i]['category']]['item'].push(json[i]);
                        }
                    }
                    for (i in category) {
                        html += '<li class="dropdown-header">' + category[i]['name'] + '</li>';
                        for (j = 0; j < category[i]['item'].length; j++) {
                            html += '<li data-value="' + category[i]['item'][j]['value'] + '"><a href="#">&nbsp;&nbsp;&nbsp;' + category[i]['item'][j]['label'] + '</a></li>';
                        }
                    }
                }
                if (html) {
                    this.show();
                } else {
                    this.hide();
                }
                $(this).siblings('ul.dropdown-menu').html(html);
            }
            $(this).after('<ul class="dropdown-menu"></ul>');
            $(this).siblings('ul.dropdown-menu').delegate('a', 'click', $.proxy(this.click, this));
        });
    }
})(window.jQuery);