$(document).ready(function () {
    $(".points_slider").on('init', function(){
        if($(window).outerWidth() > 992){
            $('.points_slider .marker').each(function (i, elem) {
                var elem = $(this);
                var elem_x = Math.abs($(this).closest('.wrapp_points').offset().left - $(elem).offset().left);
                var elem_y = Math.abs($(this).closest('.wrapp_points').offset().top - $(elem).offset().top);

                $(document).on('mousemove', function (e) {

                    if(e.target.closest('.points_slider')){
                        var cursor_x = e.clientX - $('.points_slider').offset().left;
                        var cursor_y = e.clientY - $('.points_slider').offset().top;

                        //растояние елемента к маркеру (px):
                        var lg = Math.sqrt(Math.pow(Math.abs(elem_x - cursor_x), 2) + Math.pow(Math.abs(elem_y - cursor_y), 2));

                        if(lg <= 500){
                            //растояние елемента к маркеру (%):
                            var pr = 100 * lg / 500;

                            if((100 - pr) * 0.01 > 0.4){
                                //$(elem).css({'transform':'translate(-50%, -50%) scale(-'+ (100 - pr) * 0.01 +')'});
                            }
                        }else{
                            //$(elem).css({'transform':'translate(-50%, -50%) scale(-0.4)'});
                        }
                    }else{
                        //$(elem).css({'transform':'translate(-50%, -50%) scale(-0.4)'});
                    }
                });
            });
        }
    });



    if ($('*').is('.points_slider')) {
        $('.points_slider').slick({
            dots: false,
            autoplay: true,
            infinite: true,
            arrows: false,
            speed: 2200,
            autoplaySpeed: 5000,
            slidesToShow: 1,
            adaptiveHeight: true,
            pauseOnHover: true,
            pauseOnFocus: true
        });
    }
    $('.custom_toltip').each(function (i, el) {
        var banner_width = $(this).closest('.wrapp_points').width();
        var marker_position = $(this).prev().offset().left

        if(marker_position > (banner_width/2)){
            $(this).addClass('left');
        }else{
            $(this).addClass('left');
        }

        /*if(Math.abs($(this).closest('.wrapp_points').offset().left - $(el).prev().offset().left) > 480){
            $(el).addClass('left');
        }else{
            $(el).addClass('right');
        }*/

    });


    //собитие для появления кастомного тултипа
    $('.marker').on('mouseenter', function (e) {
        var elem = $(this);
        var product_id = $(this).data('product');
        var url = 'index.php?route=module/popup_points&product_id=' + product_id;
        if (url.indexOf('#') == 0) {
            $(url).modal('open');
        } else {
            $.get(url, function (data) {
                if($(window).outerWidth() > 992) {
                    $('.custom_toltip[data-id=' + $(elem).data("for") + '] .custom_toltip_content').html(data);
                }else{
                    $('.custom_toltip[data-id=' + $(elem).data("for") + '] .custom_toltip_content').html(data);

                    $('<div class="modal fade" id="popup_points" role="dialog"><div class="modal-dialog"><div class="modal-content"><div class="modal-header"><button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" class="icon_new-close"> </span></button></div>' + $('.custom_toltip[data-id=' + $(elem).data("for") + ']').html() + '</div></div></div>').modal();
                }
            });
        }
        $(this).next('.custom_toltip[data-id='+$(this).data("for")+']').addClass('showing');

    });
    $('.marker').on('mouseleave', function (e) {
        console.log('leave');
        $('.custom_toltip[data-id='+$(this).data("for")+']').removeClass('showing');
        // $('.custom_toltip[data-id='+$(this).data("for")+']').find('.custom_toltip_content .modal-body').remove();
        // $('.custom_toltip[data-id='+$(this).data("for")+']').find('.custom_toltip_content').html('<img class="preloader" src="catalog/view/theme/default/stylesheet/popup_purchase/ring-alt.svg">')
    });
    $('.custom_toltip .custom_toltip_content').on('mousemove', function (e) {
        $(this).closest('.custom_toltip').addClass('showing');
    });
    $('.custom_toltip .custom_toltip_content').on('mouseleave', function (e) {
        $('.custom_toltip').removeClass('showing');
    });

});
